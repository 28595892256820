import React, { useEffect, useState } from 'react';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import { Schedule } from '../../schedule';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import morningSchedule from '../../../assets/static-data/2025/morningSchedule.json';
import softwareEngineeringTrack from '../../../assets/static-data/2025/softwareEngineeringTrack.json';
import aiImpactTrack from '../../../assets/static-data/2025/aiImpactTrack.json';
import whatsNextTrack from '../../../assets/static-data/2025/whatsNextTrack.json';

const SchedulePage = () => {

  const { t } = useTranslation();
  const [schedule, setSchedule] = useState(morningSchedule.schedules);
  const [isSoftwareEngineeringTrack, setSoftwareEngineeringTrack] = useState(softwareEngineeringTrack.schedules);
  const [isAIImpactTrack, setAIImpactTrack] = useState(aiImpactTrack.schedules);
  const [isWhatsNextTrack, setWhatsNextTrack] = useState(whatsNextTrack.schedules);

  useEffect(() => {
    document.title = t('Pages.Schedule.Title');

    if (!schedule) {
      setSchedule(morningSchedule);
    }

    if (!isSoftwareEngineeringTrack) {
      setSoftwareEngineeringTrack(softwareEngineeringTrack);
    }

    if (!isAIImpactTrack) {
      setAIImpactTrack(aiImpactTrack);
    }

    if (!isWhatsNextTrack) {
      setWhatsNextTrack(whatsNextTrack);
    }

  }, []);

  if (!featureToggles.schedule()) {
    return <ComingSoonPage title={t('Pages.Schedule.Title')} header={t('Pages.2025Schedule.Header') + ' - ' + t('Pages.ComingSoon.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.2025Schedule.Header')}</h1>
        </div>
        <h3 className="con-heading__s--margin-bottom-1">{t('Pages.2025Schedule.VenueLocation')}</h3>
        <Schedule schedule={schedule} t={t}/>
        <Schedule schedule={isSoftwareEngineeringTrack} t={t}/>
        <Schedule schedule={isAIImpactTrack} t={t}/>
        <Schedule schedule={isWhatsNextTrack} t={t}/>
      </div>
    </div>
  );
};

SchedulePage.propTypes = {
  scheduleList: PropTypes.array,
  t: PropTypes.func
};

export default SchedulePage;
