import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Titanic from '../../../assets/images/titanic_belfast.webp';

const VenuePage = (props) => {

  const { t } = useTranslation();
  let { title, header } = props;

  title = title || t('Pages.Venue.Title');
  header = header || t('Pages.Venue.Header');

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading">{header}</h1>
        </div>
        <div className="con-venue-image__container">
          <img className="con-venue-image"
          src={Titanic}
          alt="Titanic Belfast" />
        </div>
        <p className="con-heading__m--margin-bottom-3">
          {t('Pages.Venue.Description.Location')}
        </p>
        <p className="con-heading__s">
          {t('Pages.Venue.Description.Parking')}
        </p>
        <p className="con-text__s--margin-bottom-2">
          {t('Pages.Venue.Description.ParkingDetails')}
        </p>
        <div className="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1155.3834983188492!2d-5.911211410878587!3d54.60809724661537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486109b027638971%3A0x4c25729beb967212!2sTitanic%20Belfast!5e0!3m2!1sen!2suk!4v1718723225433!5m2!1sen!2suk" height="350px" width="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </div>
    
  );
};

VenuePage.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string
};

export default VenuePage;
