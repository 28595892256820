import React from 'react';
import './i18next';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sortJson } from './utils/sortAlphabetically';
import Footer from './components/footer';
import HomePage from './components/pages/homePage';
import NotFoundPage from './components/pages/notFoundPage';
import ComingSoonPage from './components/pages/comingSoonPage';
import SchedulePage from './components/pages/schedulePage';
import EduSchedulePage from './components/pages/eduSchedulePage';
import VenuePage from './components/pages/venuePage';
import PressReleasePage from './components/pages/pressReleasePage';
import ExpleoMeetingPage from './components/pages/expleoMeetingPage';
import EduConferencePage from './components/pages/eduConferencePage';
import RegisterPage from './components/pages/registerPage';
import DragonsDenCompaniesPage from './components/pages/dragonsDenCompaniesPage';
import HospitalityPage from './components/pages/hospitalityPage';
import { MagazinePage } from './components/pages/magazinePage';
import { ArchivePage, ArchiveYearPage, ContactPage, CuratorDetailsPage, CuratorsPage, GalleryLandingPage, GalleryPage, PartnersPage, PressReleaseDetailsPage, SpeakerDetailsPage, SpeakersPage } from '@kainos-applied-innovation/convention-frontend-components/';
import { Header } from '@kainos-applied-innovation/convention-frontend-components/dist/components/header';
import archive2022 from './assets/static-data/2022/archive.json';
import archive2023 from './assets/static-data/2023/archive.json';
import archiveList from './assets/static-data/archiveList.json';
import curators from './assets/static-data/2025/curators.json';
import contactDetails from './assets/static-data/2023/contact.json';
import partners from './assets/static-data/2025/partners.json';
import pressreleases from './assets/static-data/2024/media.json';
import schedule2023 from './assets/static-data/2023/schedule.json';
import gallery2024 from './assets/static-data/2024/gallery.json';
import eduGallery from './assets/static-data/2024/eduGallery.json';
import galleryList from './assets/static-data/galleryList.json';
import headerInfo from './assets/static-data/header.json';
import startups from './assets/static-data/2024/startups.json';
import speakers from './assets/static-data/2025/speakers.json';

function App() {

  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_BELTECH_EMAIL_API_URL;
  const recaptchaKey = process.env.REACT_APP_BELTECH_RECAPTCHA_KEY;

  return (
    <div className="con-app">
      <Header headerInfo={headerInfo} t={t} />
      <div className="con-app__container">
        <Routes>
          <Route
            path="*"
            element={<NotFoundPage />}
          />
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/register"
            element={<RegisterPage />}
          />
          <Route
            path="/coming-soon"
            element={<ComingSoonPage />}
          />
          <Route
            path="/dragons-den"
            element={<DragonsDenCompaniesPage t={t} startups={startups}/>}
          />
          <Route
            path="/schedule"
            element={<SchedulePage scheduleList={schedule2023} t={t}/>}
          />
          <Route
            path="/edu-schedule"
            element={<EduSchedulePage />}
          />
           <Route
            path="/venue"
            element={<VenuePage />}
          />
          <Route
            path="/news-and-updates"
            element={<PressReleasePage />}
          />
          <Route
            path="/news-and-updates/:pressId"
            element={<PressReleaseDetailsPage pressreleases={pressreleases} t={t} />}
          />
          <Route
            path="/partners"
            element={<PartnersPage partnerList={partners} t={t} />}
          />
          <Route
            path="2025/speakers"
            element={<SpeakersPage speakerList={sortJson(speakers, 'name')} t={t} comingSoon={false} />}
            // element={<ComingSoonPage />}
          />
          <Route
            path="2025/speakers/:speaker/:speakerId"
            element={<SpeakerDetailsPage speakersList={speakers} t={t} />}
            // element={<ComingSoonPage />}
          />
          <Route
            path="/contact"
            element={<ContactPage t={t} contactDetails={contactDetails} apiUrl={apiUrl} recaptchaKey={recaptchaKey} />}
          />
          <Route
            path="/curators"
            element={<CuratorsPage curatorsList={sortJson(curators, 'name')} t={t} />}
          />
          <Route
            path="/curators/:curator/:curatorId"
            element={<CuratorDetailsPage curatorsList={curators} t={t} />}
          />
          <Route
            path="/beltech-edu-conference"
            element={<EduConferencePage />}
          />
          <Route
            path="/beltech-edu-expleo-meeting"
            element={<ExpleoMeetingPage />}
          />
          <Route
            path="/archive"
            element={<ArchivePage archiveList={archiveList} t={t} />}
          />
          <Route
            path="/archive/2022"
            element={<ArchiveYearPage archive={archive2022} t={t} />}
          />
          <Route
            path="/archive/2023"
            element={<ArchiveYearPage archive={archive2023} t={t} />}
          />
          <Route
            path="/magazine"
            element={<MagazinePage />}
          />
          <Route
            path="/gallery/2024"
            element={<GalleryPage gallery={gallery2024} />}
          />
          <Route
            path="/gallery/edu"
            element={<GalleryPage gallery={eduGallery} />}
          />
          <Route
            path="/gallery"
            element={<GalleryLandingPage galleryList={galleryList} t={t} />}
          />
          <Route
            path="/schedule/2025-10thApril"
            element={<SchedulePage scheduleList={schedule2023} t={t}/>}
          />
          <Route
            path="/schedule/2025-11thApril"
            element={<EduSchedulePage/>}
          />
          <Route
            path="/hospitality"
            element={<HospitalityPage/>}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
